<template>
  <div class="container functionpage commonWhiteBg">
    <h2>核心功能操作说明</h2>
    <div class="function-wrap ant-row">
      <div class="function-col ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-12">
        <div class="block">
          <video poster="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/function-desc-fm1.png" controls="controls">
             <source src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/%E7%9F%A5%E9%93%BE%E8%A7%86%E9%A2%91-1%E7%89%88%E6%9D%83%E5%AD%98%E8%AF%81.mp4" type="video/mp4">
          </video>
          <p>版权存证</p>
        </div>
      </div>
      <div class="function-col ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-12">
        <div class="block">
          <video poster="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/function-desc-fm2.png" controls="controls">
             <source src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/%E7%9F%A5%E9%93%BE%E8%A7%86%E9%A2%91-2%E8%AF%81%E4%B9%A6%E6%9F%A5%E8%AF%A2.mp4
" type="video/mp4">
          </video>
          <p>证书查询</p>
        </div>
      </div>
      <div class="function-col ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-12">
        <div class="block">
          <video poster="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/function-desc-fm3.png" controls="controls">
             <source src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/%E7%9F%A5%E9%93%BE%E8%A7%86%E9%A2%91-3%E7%89%88%E6%9D%83%E7%99%BB%E8%AE%B0.mp4
" type="video/mp4">
          </video>
          <p>版权登记</p>
        </div>
      </div>
      <div class="function-col ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-12">
        <div class="block">
          <video poster="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/function-desc-fm4.png" controls="controls">
             <source src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/%E7%9F%A5%E9%93%BE%E8%A7%86%E9%A2%91-4%E7%89%88%E6%9D%83%E7%9B%91%E6%B5%8B%E4%B8%8E%E5%8F%96%E8%AF%81.mp4" type="video/mp4">
          </video>
          <p>版权监测及取证</p>
        </div>
      </div>
      <div class="function-col ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-12">
        <div class="block">
          <video poster="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/function-desc-fm5.png" controls="controls">
             <source src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/%E7%9F%A5%E9%93%BE%E8%A7%86%E9%A2%91-5%E7%89%88%E6%9D%83%E4%BA%A4%E6%98%93%EF%BD%9C%E6%8C%82%E5%8D%96.mp4" type="video/mp4">
          </video>
          <p>版权合约交易｜挂卖</p>
        </div>
      </div>
      <div class="function-col ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-12">
        <div class="block">
          <video poster="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/function-desc-fm6.png" controls="controls">
             <source src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/%E7%9F%A5%E9%93%BE%E8%A7%86%E9%A2%91-6%E7%89%88%E6%9D%83%E4%BA%A4%E6%98%93%EF%BD%9C%E8%B4%AD%E4%B9%B0.mp4" type="video/mp4">
          </video>
          <p>版权合约交易｜购买</p>
        </div>
      </div>
    </div>
  </div>

</template>


<script>

export default {
  name: 'agreement',

  data () {
    return {
    }
  },

  methods: {
   
  }
}
</script>

<style lang='scss' scoped>
.functionpage{
  padding: 24px;
  h2{margin: 24px auto;text-align: center;}
  .block{
    padding: 24px;
    // border: 1px solid #fefefe;
    // box-shadow: 0 0 4px rgba(0, 109, 117, 0.06);
    video{width: 100%;}
    p{font-size: 18px;margin: 8px auto 24px;text-align: center;}
  }
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .functionpage{
  padding: 16px;
  .block{
    padding: 8px;
    // border: 1px solid #fefefe;
    // box-shadow: 0 0 4px rgba(0, 109, 117, 0.06);
    video{width: 100%;}
    p{font-size: 18px;margin: 8px auto 24px;text-align: center;}
  }
}
}
</style>
